import { post } from 'utils/sdk';
import { BASE_URL } from 'config/urls';

export const setSelfUserPassword = ({ token, email, password }: { token: string; email: string; password: string }) =>
  post<{ message: string }>(BASE_URL + 'api/v2/auth/set-password', {
    token,
    email,
    password,
    password_confirmation: password,
  });

export const resetSelfUserPassword = ({ token, email, password }: { token: string; email: string; password: string }) =>
  post(BASE_URL + 'api/v2/auth/reset-password', {
    token,
    email,
    password,
    password_confirmation: password,
  });
