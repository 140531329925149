import TanzaniaSpecialistLogo from 'assets/tanzania-specialist-logo.png';
import AfricaSafariLogo from 'assets/africa-safari-logo.png';

import styles from './styles.module.css';

const UnauthPageHeader = () => {
  return (
    <div className={styles.layout}>
      <img src={TanzaniaSpecialistLogo} height='95vh' />
      <img src={AfricaSafariLogo} height='75vh' />
    </div>
  );
};

export default UnauthPageHeader;
