import UnauthPageHeader from 'components/UnauthPageHeader';
import TripImage from 'assets/default-trip-image.png';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <UnauthPageHeader />
      <img src={TripImage} height={300} style={{ margin: '32px' }} />
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFound;
