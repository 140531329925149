import { useState } from 'react';
import _ from 'lodash';
import { setSelfUserPassword } from 'entities/Auth/sdk';
import { getUrlGetParams } from 'utils/urls';

import TanzaniaSpecialistLogo from 'assets/tanzania-specialist-logo.png';
import AfricaSafariLogo from 'assets/africa-safari-logo.png';

import styles from './styles.module.css';

const SetPassword = () => {
  const [password, setPassword] = useState<string>('');
  const { token = '', email = '' } = getUrlGetParams();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<null | { [key: string]: string }>(null);

  const handleSubmit = () => {
    setErrors(null);
    setLoading(true);
    setSelfUserPassword({ token, email, password })
      .then(() => setSuccess(true))
      .catch((error) => {
        error.response?.text()?.then((data: string) => setErrors(JSON.parse(data)));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.layout}>
      <div className={styles.logosContainer}>
        <img src={TanzaniaSpecialistLogo} width={450} />
        <img src={AfricaSafariLogo} width={200} />
      </div>
      <div className={styles.box}>
        {success && <div className={styles.successAlert}>Your password was successfully set! You can now log in!</div>}
        {errors && (
          <div className={styles.errorAlert}>
            Error:
            <br />
            {errors.message}
            <br />
            {_.map(errors.errors, (value: string) => value)}
          </div>
        )}
        {!success && (
          <>
            <h1>Set Password</h1>
            <div className={styles.subtitle}>
              Set the password for your
              <br />
              Africa Safari and
              <br />
              Tanzania Specialist
              <br />
              account
            </div>
            <form className={styles.fieldWrapper}>
              <input type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} autoFocus />
              <button className={styles.button} onClick={handleSubmit} disabled={loading}>
                {loading ? 'Loading...' : 'Set password'}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default SetPassword;
