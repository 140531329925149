import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { URLS } from 'config/urls';

import SetPassword from 'pages/SetPassword';
import NotFound from 'pages/NotFound';

const router = createBrowserRouter([
  {
    path: URLS.SET_PASSWORD,
    element: <SetPassword />,
  },
  {
    path: '*', // Catch-all route for undefined paths
    element: <NotFound />,
  },
]);

const Routes = () => <RouterProvider router={router} />;

export default Routes;
